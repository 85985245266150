<template>
  <div class="form--group other--facility-section">
    <div class="left--col">
      <span>{{ $t('addListing.otherFacility') }}</span>
    </div>
    <div class="right--col">
      <other-facility-field
        v-for="(otherFacility, index) in otherFacilities"
        :key="index"
        :index="index"
        :otherFacility="otherFacility"
        ref="otherFacilityField"
        :length="otherFacilities.length"
      />
      <div class="col-12 pa-0 mt-2">
        <button
          :disabled="otherFacilities.length >= 5"
          @click="addOtherFacility"
          class="btn btn-primary--light btn-block"
          type="button"
          id="addOtherFacilityBtn"
        >
          {{ $t('addListing.btn.addOtherFacility') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const OtherFacilityField = () =>
  import('@/components/listing-form/sect2/other-facilities/other-facility-field.vue');
export default {
  name: 'other-facilites-section',
  components: { OtherFacilityField },
  computed: {
    ...mapState({
      otherFacilities: (state) => state.v2.listingForm.sect2.detail.otherFacilities,
    }),
  },
  methods: {
    validate() {
      let validationArray = [];
      const facilityValidation = this.$refs.otherFacilityField.map(function (form) {
        return form.validate();
      });
      validationArray.push(facilityValidation);
      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            return !result;
          }).length === 0
        );
      });
    },

    addAmenity() {
      this.$store.commit('v2/listingForm/sect1/location/ADD_AMENITY');
      this.$nextTick(function () {
        this.$refs.amenityField[this.amenities.length - 1].$el.querySelector('input').focus();
      });
    },
    addOtherFacility() {
      this.$store.commit('v2/listingForm/sect2/detail/ADD_OTHER_FACILITY');
      this.$nextTick(function () {
        this.$refs.otherFacilityField[this.otherFacilities.length - 1].$el
          .querySelector('input')
          .focus();
      });
    },
  },
};
</script>

<style scoped></style>
